<template>
  <v-app>
    <div>
      <b-form @submit.stop.prevent="formOnsubmit">
        

        <!-- Input  Action Type-->
        <b-form-group
          id="input-group-package-name"
          label="Nama Sortcut:"
          label-for="input-package-name"
        >
          <b-form-input
              id="input-package-medicine"
              v-model="package_name"
              placeholder="Nama Sortcut"
              required
            >
            </b-form-input>
          <small class="text-danger">{{ error.package_name }}</small>
        </b-form-group>

        <!-- Input  Action Type-->
        <b-form-group
          id="input-group-action-type"
          label="Jenis Tindakan:"
          label-for="input-action-type"
        >
          <treeselect
            v-model="action_type"
            @select="handleType"
            :multiple="false"
            
            placeholder="Jenis Tindakan"
            :options="action_types"
          />
          <small class="text-danger">{{ error.action_type }}</small>
        </b-form-group>

        <!-- Input Modal Trigger -->
        <b-form-group id="input-group-trigger-modal-medicine">
          <label for="input-trigger-modal-medicine"
            >Obat dan Alkes: <em class="text-muted">opsional</em></label
          >
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-medicine"
              v-model="display.medicine_name"
              placeholder="Pilih Obat dan Alkes"
              readonly
              @click="$bvModal.show('modal-medicine')"
              style="border-right: none"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetMedicine"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.medicines }}</small>
        </b-form-group>

        <div class="overflow-auto">
          <div class="bg-light" v-if="coll_medicine.length > 0">
            <table class="table" v-if="coll_medicine.length > 0">
              <tr>
                <th>Nama</th>
                <th>Kuantitas</th>
                <th>Harga Jual</th>
                <th>Total Harga</th>
                <th>Aksi</th>
              </tr>
              <tr v-for="mdc in coll_medicine" :key="mdc.id">
                <td>{{ mdc.name }}</td>
                <td>{{ mdc.quantity }}</td>
                <td>{{ mdc.sales_price }}</td>
                <td>{{ mdc.total_price }}</td>
                <td>
                  <b-button
                    size="sm"
                    class="btn-danger"
                    v-b-tooltip.hover.left
                    title="Hapus Obat dan Alkes"
                    type="button"
                    @click="removeMedicine(mdc.id)"
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Submit & Cancel button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/medicine-packages')"
        >
          Batal
        </b-button>
      </b-form>

      <b-modal
        ref="my-modal"
        hide-footer
        title="Resep Obat"
        @hide="hideModalCancel"
      >
        <b-form @submit.stop.prevent="medicineFormOnsubmit">
          <!-- <div class="d-block text-center"> -->
          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="coll_medicine_form.name"
              placeholder="Nama obat"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-quantity"
            label="Kuantitas:"
            label-for="input-quantity"
          >
            <b-form-input
              id="input-quantity"
              v-model="coll_medicine_form.quantity"
              placeholder="Kuantitas"
              required
            ></b-form-input>
          </b-form-group>
          <!-- </div> -->
          <!-- Submit & Cancel button -->
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="hideModalCancel"
          >
            Cancel
          </b-button>
        </b-form>
      </b-modal>

     
      <ModalMedicine
        @chosenMedicine="setPrescription"
        :selectedItem="coll_medicine"
        :checkbox="true"
      />
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalMedicine from "@/component/general/ModalMedicine.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service.js";

export default {
  props: {
    form: Object,
    route: String,
  },

  components: {
   
    ModalMedicine,
  },

  data() {
    return {
      modal2: false,
      // Display Data
      display: {
        patient_name: this.$route.query.patient_name ?? "",
        assistant_name: "",
        omploop_name: "",
        medicine_name: "",
      },
      // Error
      error: {
        package_name:"",
        patient_id: null,
        patient_name: "",
        patient_age: "",
        electro_laser: "",
        action_type: "",
        operator: "",
        assistant: "",
        omploop: "",
        anesteshia: "",
        anesthetist: null,
        notes: "",
        date: "",
        medicines: "",
      },
      
      action_types: [],
    //  form
      action_type: [],
      package_name:"",
     
      // Action Type
      coll_action_type_form: {
        id: "",
        name: "",
      },
      coll_action_type: [],
      coll_action_type_str: "",
    
      // medicines
      coll_medicine_form: {
        id: "",
        name: "",
        sales_price: "",
        quantity: "",
        total_price: "",
      },
      coll_medicine: [],
      coll_medicine_str: "",
      uploadAttachmentURL: "",
     
      // data
      formData: new FormData(),
      // other
      btnClearMedicineClass: "bg-white",
      medicine_collection_submit: false,
      medicine_id: "",
      medicine: "",
      name_medicine: "",
      // Pagination
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "action_type",
          label: "Diagnosa Tindakan",
          sortable: true,
        },
        {
          key: "operator",
          label: "Operator",
          sortable: true,
        },
        {
          key: "assistant",
          label: "Asisten",
          sortable: true,
        },
        {
          key: "anesteshia",
          label: "Anastesi",
          sortable: true,
        },
        {
          key: "anesthetist",
          label: "Dokter Anastesi",
          sortable: true,
        },
        {
          key: "medicines",
          label: "Obat/Alkes",
          sortable: true,
        },
      ],
      // Table Items
      items: [],
    };
  },

  methods: {
    async pagination(id) {
      let filterParams = ``;
      let response = await module.paginate(
        "medical-actions/get-by-patient/" + id,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        let date = new Date(response.data[a].date)
        let convert = date.toLocaleString().split(",")[0]
        response.data[a].number = b + a;
        response.data[a].date = convert
        
      }

      this.items = response.data;
      
      this.$bvModal.hide("modal-patient");
    },

    async handleType(evt){
      let found = this.action_types.findIndex(x => x.id == evt.id)
     
      // let response = await module.get('remuneration-types/'+ found)
      this.form.action_id = evt.id
      // this.form.action_name = evt.name
    },

   
    async setPrescription(value) {
      
      if (this.coll_medicine.length > 0) {
        let a;
        for (a = 0; a < this.coll_medicine.length; a++) {
          if (this.coll_medicine[a].id == value.id) {
            this.coll_medicine.splice(a, 1);
            if (this.coll_medicine.length == 0) {
              this.display.medicine_name = "";
              break;
            } else if (this.coll_medicine.length > 0) {
              this.displayMedicineName(this.coll_medicine);
              break;
            }
            break;
          } else if (a == this.coll_medicine.length - 1) {
            this.$bvModal.hide("modal-medicine");
            
            await this.medicine_id;
            this.medicine_id = value.id;
            
            // this.coll_medicine_form.id = "";
            this.coll_medicine_form.name = "";
            this.coll_medicine_form.sales_price = "";
            this.coll_medicine_form.quantity = "";
            this.setMedicine(value.id);
          }
        }
      } else {
        this.$bvModal.hide("modal-medicine");
        
        await this.medicine_id;
        this.medicine_id = value.id;
        
        // this.coll_medicine_form.id = "";
        this.coll_medicine_form.name = "";
        this.coll_medicine_form.sales_price = "";
        this.coll_medicine_form.quantity = "";
        this.setMedicine(value.id);
      }
    },

    setMedicine(id) {
      ApiService.get("medicines/" + id).then((response) => {
        
        this.medicine = response.data.data;
        this.name_medicine = response.data.data.name;
        this.coll_medicine_form.name = response.data.data.name;
        this.coll_medicine_form.id = response.data.data.id;
        this.coll_medicine_form.sales_price = response.data.data.sales_price;
        this.$refs["my-modal"].show();
      });
    },

    displayMedicineName(medicine) {
      if (medicine.length == 0) {
        this.display.medicine_name = "";
      } else {
        let str = "";
        medicine.forEach(function (value, index) {
          str += value.name;
          if (index != medicine.length - 1) {
            if (medicine.length > 1) str += ", ";
          }
        });
        this.display.medicine_name = str;
      }
    },

    medicineFormOnsubmit() {
      
      
      this.coll_medicine_form.total_price =
        parseInt(this.coll_medicine_form.quantity) *
        parseInt(this.coll_medicine_form.sales_price);
      this.coll_medicine_form.total_price = parseInt(
        this.coll_medicine_form.total_price
      ).toLocaleString("id-ID");
      this.coll_medicine_form.sales_price = parseInt(
        this.coll_medicine_form.sales_price
      ).toLocaleString("id-ID");

      this.medicine_collection_submit = true;
      let clone = { ...this.coll_medicine_form };
      this.coll_medicine.push(clone);

      
      this.displayMedicineName(this.coll_medicine);
      this.hideModal();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },

    async hideModalCancel() {
      if (this.medicine_collection_submit == false) {
        
        // this.medicine_id.pop();
      }
      await this.$refs["my-modal"].hide();
      this.setMedicineSubmit();
    },

    setMedicineSubmit() {
      this.medicine_collection_submit = false;
    },

    

    resetMedicine() {
      this.coll_medicine.splice(0, this.coll_medicine.length);
      
      this.display.medicine_name = "";
    },

    removeMedicine($id) {
      
      let a;
      for (a = 0; a < this.coll_medicine.length; a++) {
        if (this.coll_medicine[a].id == $id) {
          this.coll_medicine.splice(a, 1);
          
        }
      }
      this.displayMedicineName(this.coll_medicine);
    },

    // displayMedicineName(medicine) {
    //   if (medicine.length == 0) {
    //     this.display.medicine_name = "";
    //   } else {
    //     let str = "";
    //     medicine.forEach(function (value, index) {
    //       str += value.name;
    //       if (index != medicine.length - 1) {
    //         if (medicine.length > 1) str += ", ";
    //       }
    //     });
    //     this.display.medicine_name = str;
    //   }
    // },

    async getActionTypes() {
      let response = await module.setTreeSelect("payment-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.action_types = response.data;
      }
    },

    

  

    setActionType(evt) {
      
      this.coll_action_type_form.id = evt.id;
      this.coll_action_type_form.name = evt.label;

      let clone = { ...this.coll_action_type_form };
      this.coll_action_type.push(clone);
      
    },

    removeActionType(event) {
      
      

      let a;
      for (a = 0; a < this.coll_action_type.length; a++) {
        if (event.id == this.coll_action_type[a].id) {
          
          this.coll_action_type.splice(a, 1);
        }
      }
    },

    async formOnsubmit() {
    

     

      this.coll_medicine_str = await JSON.stringify(this.coll_medicine);
      this.form.medicines = await this.coll_medicine_str;
      this.form.package_name = this.package_name;
      

      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(this.formData, this.route);

    
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/medicine-packages");
      }
    },
  },

  mounted() {
    // this.getDoctorOption();
    this.getActionTypes();
  },

  watch: {
    "form.patient_name": function (newVal, oldVal) {
      this.display.patient_name = newVal;
    },
  },
};
</script>

<style scoped>
.nowrap {
  white-space: normal;
}

@media (max-width: 767.98px) {
  .nowrap {
    white-space: nowrap;
  }
}
</style>